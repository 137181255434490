import * as TYPES from './types';

export function showLoading() {
    return {
        type: TYPES.LOADING_SHOW,
        payload: { visible: true }
    }
}

export function hideLoading() {
    return {
        type: TYPES.LOADING_HIDE,
        payload: { visible: false }
    }
}

export function toggleLoading() {
    return {
        type: TYPES.TOGGLE_LOADING
    }
}