import * as TYPES from './types';

export function showAlert(payload) {
    return {
        type: TYPES.ALERT_SHOW,
        payload
    }
}

export function hideAlert() {
    return {
        type: TYPES.ALERT_HIDE
    }
}