/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import "moment/locale/pt-br";
import { Spinner, Alert } from "reactstrap";
import EStatus from "../../enums/status";
import classnames from "classnames";
import { api } from "../../utils";
import EDiaSemana from "../../enums/semana";

const ScheduleList = () => {
  moment.locale("pt-br");

  const [dataTreino, setDataTreino] = useState("");
  const [treinosPorDatas, setTreinosPorDatas] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ultimaAtulizacao, setUltimaAtualizacao] = useState(
    moment().calendar()
  );
  const [datas] = useState([
    {
      moment: moment().add(0, "days"),
    },
    {
      moment: moment().add(1, "days"),
    },
  ]);

  const getAgendamentos = (dataTreino, treinoId) => {
    return new Promise((resolve, reject) => {
      api
        .getAgendamentos({
          data: dataTreino,
          treino_id: treinoId,
        })
        .then((response) => {
          resolve(response);
        });
    });
  };

  const getTreinos = (diaTreino) => {
    return new Promise((resolve, reject) => {
      api
        .getTreinos({
          dia: diaTreino,
        })
        .then((response) => {
          resolve(response.data);
        });
    });
  };

  const getTreinosPorDatas = async (datas) => {
    return Promise.all(
      datas.map(async (data) => {
        const treinosPorData = await getTreinosPorData(data.moment);
        data.treinos = treinosPorData;
        return data;
      })
    );
  };

  const getTreinosPorData = async (data) => {
    const treinos = await getTreinos(data.format("d"));

    return Promise.all(
      treinos.map(async (treino) => {
        const agendamentos = await getAgendamentos(
          data.format("YYYY-MM-DD"),
          treino.id
        );
        treino.agendamentos = agendamentos.data;
        let vagas_ocupadas = [];
        let posicao = 0;

        treino.agendamentos.map((agendamento) => {
          if (agendamento.status !== "1") {
            vagas_ocupadas.push(agendamento);
            posicao++;
            agendamento.posicao = posicao;
          }

          return agendamento;
        });

        treino.vagas_disponiveis = treino.vagas - vagas_ocupadas.length;

        return treino;
      })
    );
  };

  async function fetchData() {
    const treinosPorData = await getTreinosPorDatas(datas);

    treinosPorData.map((data) => {
      data.temAgendamentos = false;
      data.temTreinos = data.treinos.length > 0;

      for (var i = 0; i < data.treinos.length; i++) {
        if (data.treinos[i].agendamentos.length > 0) {
          data.temAgendamentos = true;
          break;
        }
      }

      return data;
    });

    setTreinosPorDatas(treinosPorData);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [ultimaAtulizacao]);

  const { handleSubmit, register, errors, reset } = useForm();
  const [alert, setAlert] = useState(null);
  const onSubmit = (values) => {
    setLoading(true);
    setAlert(null);

    const agendamento = {
      ...values,
      treino_id: Number(values.treino_id),
      data: treinosPorDatas[values.data_treino].moment.format("DD/MM/yyyy"),
      status: 0,
      criado_em: moment().format(),
      aluno_nome: values.aluno_nome.toLowerCase(),
      aluno_email: values.aluno_email.toLowerCase(),
    };

    delete agendamento.data_treino;

    postAgendamentos(agendamento);
  };

  const postAgendamentos = async (form) => {
    // console.log('postAgendamentos', data);

    api
      .postAgendamentos(form)
      .then((response) => {
        if (response.data) {
          setAlert({
            type: "success",
            msg: "Solicitação enviada com sucesso!",
          });

          reset();
          setUltimaAtualizacao(moment().calendar());
          fetchData();
        }
      })
      .catch((reason) => {
        setAlert({
          type: "danger",
          msg: reason.response.data.msg,
        });
        setLoading(false);
      });
  };

  const handlerChangeData = (e) => {
    setDataTreino(e.target.value);
  };

  return (
    <div className="schedule-list">
      {treinosPorDatas &&
        treinosPorDatas.map((data, key) => {
          if (data.temAgendamentos) {
            return (
              <div key={key}>
                <h3>{`${
                  EDiaSemana[data.moment.format("d")]
                }, ${data.moment.format("DD/MM/yyyy")}`}</h3>

                {data.treinos.map((treino) => {
                  if (treino.agendamentos.length > 0) {
                    return (
                      <div key={treino.id}>
                        <h4>
                          {treino.horario} - {treino.vagas} Vagas
                        </h4>

                        <table className="alt">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Aluno</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {treino.agendamentos.map((agendamento) => {
                              return (
                                <tr
                                  key={agendamento.id}
                                  className="text-center"
                                >
                                  <td>{agendamento.posicao}</td>
                                  <td>{agendamento.aluno_nome}</td>
                                  <td
                                    className={classnames({
                                      "text-warning": agendamento.status === 0,
                                      "text-danger": agendamento.status === 1,
                                      "text-success": agendamento.status === 2,
                                    })}
                                  >
                                    {EStatus[agendamento.status]}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            );
          } else {
            return null;
          }
        })}

      <p>
        <i>Lista atualizada {ultimaAtulizacao}</i>
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Solicite sua vaga</h3>
        {alert ? <Alert color={alert.type}>{alert.msg}</Alert> : null}
        <div className="row uniform 50%">
          <div className="6u 12u$(xsmall)">
            <input
              disabled={loading}
              type="text"
              name="aluno_nome"
              placeholder="Nome"
              ref={register({
                required: true,
                pattern: {
                  value: /[A-z]{2} [A-z]{2}/,
                  message: "Digite o nome completo",
                },
              })}
            />
            {errors.aluno_nome ? (
              <p className="text-danger">{errors.aluno_nome.message}</p>
            ) : null}
          </div>
          <div className="6u$ 12u$(xsmall)">
            <input
              disabled={loading}
              type="email"
              name="aluno_email"
              placeholder="Email"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Digite um email válido",
                },
              })}
            />
            {errors.aluno_email && errors.aluno_email.message}
          </div>
          <div className="12u$">
            <div className="select-wrapper">
              <select
                onChange={handlerChangeData}
                disabled={loading}
                name="data_treino"
                ref={register({
                  required: true,
                  pattern: {
                    value: /([^\s])/,
                    message: "Selecione uma data",
                  },
                })}
              >
                <option value="">Selecione uma data</option>
                {treinosPorDatas &&
                  treinosPorDatas.map((data, key) => {
                    if (data.temTreinos) {
                      return (
                        <option value={key} key={key}>{`${
                          EDiaSemana[data.moment.format("d")]
                        }, ${data.moment.format("DD/MM/yyyy")}`}</option>
                      );
                    } else {
                      return null;
                    }
                  })}
              </select>
              {errors.data_treino && errors.data_treino.message}
            </div>
          </div>
          <div className="12u$">
            {dataTreino !== "" ? (
              <div className="select-wrapper">
                <select
                  disabled={loading}
                  name="treino_id"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /([^\s])/,
                      message: "Selecione um treino",
                    },
                  })}
                >
                  <option value="">Selecione um treino</option>
                  {treinosPorDatas[dataTreino].treinos.map((treino) => {
                    if (treino.vagas_disponiveis > 0) {
                      return (
                        <option key={treino.id} value={treino.id}>
                          {treino.horario}
                          {treino.observacao
                            ? ` - ${treino.observacao}`
                            : null}{" "}
                          ({treino.vagas_disponiveis} vagas)
                        </option>
                      );
                    } else {
                      return null;
                    }
                  })}
                </select>
              </div>
            ) : null}
          </div>
          <div className="12u$">
            {loading ? (
              <Spinner />
            ) : (
              <ul className="actions">
                <li>
                  <button disabled={loading} type="submit" className="special">
                    Solicitar
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ScheduleList;
