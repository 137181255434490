import axios from "axios";
import { store } from "../redux/store";
import * as action from "../redux/actions";
import axiosConfig from "config/axios";

export const request = axios.create(axiosConfig);

const state = store.getState();

if (state?.user?.token) {
  request.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${state.user.token}`;
}

request.interceptors.request.use(
  (config) => {
    store.dispatch(action.showLoading());

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    store.dispatch(action.hideLoading());

    return response;
  },
  (error) => {
    store.dispatch(action.hideLoading());

    const payload = error.response ? error.response : error.toJSON();

    let message = "Não foi possivel realizar essa operação";

    if (payload.data && payload.data.sqlMessage) {
      message = payload.data.sqlMessage;
    }

    if (payload.data && payload.data.msg) {
      message = payload.data.msg;
    }

    store.dispatch(
      action.showAlert({
        message,
        type: "error",
        title: "Erro",
      })
    );

    if (error.response?.status === 401) {
      store.dispatch(action.deleteUser());
    }

    return Promise.reject(error);
  }
);

const getTreinos = async (params) => {
  return await request.get("/treinos", {
    params: params,
  });
};

const getTreinosAtivos = async (params) => {
  return await request.get("/treinos/ativos", {
    params: params,
  });
};

const getAgendamentos = async (params) => {
  // console.log('getAgendamentos', params);

  const response = await request.get("/agendamentos", { params });

  return response.data;
};

const getProfessores = async (params) => {
  return await request.get("/professores", {
    params: params,
  });
};

const getAlunos = async (params) => {
  return await request.get("/alunos", {
    params: params,
  });
};

const getModalidades = async (params) => {
  return await request.get("/modalidades", {
    params: params,
  });
};

const postAgendamentos = async (data) => {
  return await request.post("/agendamentos", data);
};

const putAgendamentos = async (data) => {
  return await request.put(`/agendamentos/${data.id}`, data);
};

const login = async (data) => {
  return await request.post("/autenticacao/admin/login", data);
};

const getPresencas = async (params) => {
  return await request.get("/agendamentos/intervalo", {
    params: params,
  });
};

const api = {
  getPresencas,
  getAlunos,
  login,
  getTreinos,
  getTreinosAtivos,
  getAgendamentos,
  postAgendamentos,
  putAgendamentos,
  getProfessores,
  getModalidades,
};

export default api;
