import styled from 'styled-components';

export const AlertWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    cursor: pointer;

    strong {
        color: inherit;
        margin-right: 8px;
    }

    .alert {
        border-radius: 0;

        button.close {
            top: 10px;
            right: 10px;
            position: absolute;
            z-index: 10;
            width: 32px;
            height: 32px;
        }
    }
`;