import React, { useState } from "react";
import "./header.css";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "../../redux/store";
import * as action from "../../redux/actions";
import verifyProfile from "utils/verify-profile";

const Header = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const { user } = props;

  const Logout = () => {
    setShowMenu(false);
    store.dispatch(action.deleteUser());
  };

  return (
    <header id="header" className="alt">
      <nav id="nav" className="navigation component component-navigation">
        <div className="header-main">
          <h1>APM - Admin</h1>
          {user.token ? (
            <button
              onClick={toggleMenu}
              href="#menu"
              className="navPanelToggle"
            >
              <span className="fa fa-bars bt-icon"></span>
            </button>
          ) : null}
        </div>
        {showMenu ? (
          <ul>
            {verifyProfile(user, ["admin", "professor"]) ? (
              <>
                <li>
                  <NavLink onClick={() => setShowMenu(false)} to="/presenca">
                    Presença
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => setShowMenu(false)} to="/turmas">
                    Turmas
                  </NavLink>
                </li>
              </>
            ) : null}
            {verifyProfile(user, ["admin"]) ? (
              <>
                <li>
                  <NavLink onClick={() => setShowMenu(false)} to="/alunos">
                    Alunos
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => setShowMenu(false)} to="/usuarios">
                    Usuarios
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => setShowMenu(false)} to="/modalidades">
                    Modalidades
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => setShowMenu(false)} to="/planos">
                    Planos
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => setShowMenu(false)} to="/produtos">
                    Produtos
                  </NavLink>
                </li>
              </>
            ) : null}
            <li>
              <button className="btn btn-sm btn-link" onClick={Logout}>
                Sair
              </button>
            </li>
          </ul>
        ) : null}
      </nav>
    </header>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Header);
