import React, { useEffect, useCallback } from "react";
import { Alert as RSAlert, Button } from "reactstrap";
import { AlertWrapper } from "./alert.styled";
import { store } from "../../redux/store";
import * as action from "../../redux/actions";

const Alert = (props) => {
  const { visible, message, type, title } = props;
  const color = {
    error: "danger",
  };

  const hideAlert = useCallback(() => {
    store.dispatch(action.hideAlert());
  }, []);

  useEffect(() => {
    // If visible, set a timeout to hide the alert after 5 seconds
    if (visible) {
      const timeoutId = setTimeout(hideAlert, 30000);

      // Cleanup function to clear the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [visible, hideAlert]);

  return visible ? (
    <AlertWrapper onClick={hideAlert}>
      <RSAlert color={color[type]}>
        <strong>{title}</strong>
        <span>{message}</span>

        <Button close />
      </RSAlert>
    </AlertWrapper>
  ) : null;
};

export default Alert;
