import * as TYPES from '../actions/types';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? user : { token: false };

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.USER_SET:
            return {
                ...action.payload
            };
        case TYPES.USER_DELETE:
            return {
                token: false
            }
        default:
            return state;
    }
}

export default reducer;