import * as TYPES from "../actions/types";

const initialState = {
  alunos: [],
  horarios: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.TURMA_SET_ALUNOS:
      return {
        alunos: action.alunos,
        horarios: state.horarios,
      };
    case TYPES.TURMA_SET_HORARIOS:
      return {
        alunos: state.alunos,
        horarios: action.horarios,
      };
    default:
      return state;
  }
};

export default reducer;
