import * as TYPES from '../actions/types';

const initialState = {
    visible: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.LOADING_SHOW:
            return {
                ...state,
                visible: true
            }
        case TYPES.LOADING_HIDE:
            return {
                ...state,
                visible: false
            }
        default:
            return state;
    }
}

export default reducer;