import React, { useEffect, useState } from "react";
import { api } from "../../utils";
import "./schedule-table.css";

const ScheduleTable = () => {
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    async function getTreinos() {
      const response = await api.getTreinosAtivos();
      setSchedules(response.data);
    }

    getTreinos();
  }, []);

  const grade = [
    {
      dia_semana: "Domingo",
      treinos: [],
    },
    {
      dia_semana: "Segunda",
      treinos: [],
    },
    {
      dia_semana: "Terça",
      treinos: [],
    },
    {
      dia_semana: "Quarta",
      treinos: [],
    },
    {
      dia_semana: "Quinta",
      treinos: [],
    },
    {
      dia_semana: "Sexta",
      treinos: [],
    },
    {
      dia_semana: "Sábado",
      treinos: [],
    },
  ];

  schedules.map((item) => {
    grade[item.dia].treinos.push(item);

    return item;
  });

  return (
    <div className={`table-wrapper`}>
      {grade.map((item, key) => {
        if (item.treinos.length > 0) {
          return (
            <table key={key} className="alt">
              <thead>
                <tr>
                  <th colSpan="2">{item.dia_semana}</th>
                  <th>Vagas</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {item.treinos.map((treino) => {
                  return (
                    <React.Fragment key={treino.id}>
                      <tr>
                        <td>{treino.horario}</td>
                        <td>
                          {treino.modalidade_id === 1
                            ? "Jiu Jitsu"
                            : "Jiu Jitsu Kids"}
                        </td>
                        <td>{treino.vagas}</td>
                        <td>{treino.tipo}</td>
                      </tr>
                      {treino.status === 1 ? (
                        <tr>
                          <td colSpan="4">
                            <i className="observacao">
                              * Para treinar nas turmas em formação, entre em
                              contato pelo{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://wa.me/5511988701860?text=Contato%20pelo%20site%20"
                              >
                                WhatsApp: (11) 98870-1860
                              </a>
                            </i>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default ScheduleTable;
