import { combineReducers } from "redux";
import loading from "./loading";
import alert from "./alert.reducer";
import user from "./user.reducer.js";
import turma from "./turma.reducer";

const rootReducer = combineReducers({
  loading,
  alert,
  user,
  turma,
});

export default rootReducer;
