import * as TYPES from './types';
import { request } from '../../utils/api';

export function setUser(payload) {
    // console.log('setUser', payload);
    localStorage.setItem("user", JSON.stringify(payload));
    request.defaults.headers.common["Authorization"] = `Bearer ${payload.token}`;

    return {
        type: TYPES.USER_SET,
        payload
    }
}

export function deleteUser() {
    localStorage.removeItem('user');

    return {
        type: TYPES.USER_DELETE
    }
}