import React, { Suspense, lazy } from "react"
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/index.css';
import { Provider } from "react-redux"
import { store } from './redux/store';
import { Loader } from './components';

const LazyApp = lazy(() => import("./App"))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <LazyApp />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();