export const LOADING_SHOW = "LOADING_SHOW";
export const LOADING_HIDE = "LOADING_HIDE";
export const TOGGLE_LOADING = "TOGGLE_LOADING";

export const ALERT_SHOW = "ALERT_SHOW";
export const ALERT_HIDE = "ALERT_HIDE";

export const USER_SET = "USER_SET";
export const USER_DELETE = "USER_DELETE";

export const TURMA_SET_ALUNOS = "TURMA_SET_ALUNOS";
export const TURMA_SET_HORARIOS = "TURMA_SET_HORARIOS";
